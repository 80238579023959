<template>
  <section id="range-filter-wrapper">
    <label for="range-filter">{{
      lang.metrics.filters.range.date[languageSelected]
    }}</label>

    <date-picker
      range
      v-model="dates"
      :lang="languageSelected"
      :key="languageSelected"
      :format="dateFormat"
      :disabled-date="afterToday"
      :input-attr="{ id: 'range-filter' }"
      input-class="vs-inputx vs-input--input normal"
      :append-to-body="false"
      :range-separator="
        lang.metrics.filters.range.rangeSeparator[languageSelected]
      "
      :placeholder="
        lang.metrics.filters.range.rangePlaceholder[languageSelected]
      "
      :clearable="false"
      @change="onChange"
    >
      <template slot="sidebar" slot-scope="{ emit }">
        <button class="mx-btn mx-btn-text" @click="selectLastWeek(emit)">
          {{ lang.metrics.filters.range.lastWeek[languageSelected] }}
        </button>
        <button class="mx-btn mx-btn-text" @click="selectLastMonth(emit)">
          {{ lang.metrics.filters.range.lastMonth[languageSelected] }}
        </button>
      </template>
    </date-picker>

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.reset[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import 'vue2-datepicker/locale/es'

export default {
  name: 'DateRangeFilter',
  props: {
    value: Object
  },
  data() {
    return {
      dates: []
    }
  },
  components: {
    DatePicker: () => import('vue2-datepicker')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    dateFormat() {
      return this.languageSelected === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY'
    }
  },
  methods: {
    afterToday(date) {
      const today = new Date()
      today.setHours(23, 59, 59)
      return date > today
    },
    getLastWeek() {
      const start = new Date()
      const end = new Date()
      start.setTime(start.getTime() - 7 * 24 * 3600 * 1000)
      const date = [start, end]
      return date
    },
    selectLastWeek(emit) {
      emit(this.getLastWeek())
    },
    selectLastMonth(emit) {
      const start = new Date()
      const end = new Date()
      start.setTime(start.getTime() - 30 * 24 * 3600 * 1000)
      const date = [start, end]
      emit(date)
    },
    onChange() {
      if (this.afterToday(this.dates[1])) {
        this.$vs.notify({
          text: this.lang.metrics.filters.range.messageDeadline[
            this.languageSelected
          ],
          color: 'warning'
        })
        this.dates[1] = new Date()
        return
      }
      this.showWarning = false
      const dates = [
        new Date(this.dates[0].setHours(0, 0, 0, 0)),
        new Date(this.dates[1].setHours(23, 59, 59, 999))
      ]

      this.$emit('change', {
        type: 'date',
        value: dates //this.dates
      })
    },
    clear() {
      this.dates = this.getLastWeek()
      this.onChange()
    }
  },
  mounted() {
    // init range
    this.dates.push(this.value.from, this.value.to)
  }
}
</script>

<style lang="scss">
$primary-color: #ff8100;
@import '~vue2-datepicker/index.css';
@import '~vue2-datepicker/scss/index.scss';

#range-filter-wrapper {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
</style>
